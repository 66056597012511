import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Card, TextField, createTheme } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  appdataSelector,
  appdata_createDongle,
  appdata_fetchAppdata,
  appdata_updateNewDongleData,
  appdata_downloadDongle,
  appdata_fetchDongleData,
  appdata_setDongleData,
  appdata_setSnackbar
} from "reducers/appdata/appdata";
import Loader from "components/Loading/Loader";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import 'dayjs/locale/en';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { getUser } from "services/auth";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#008752"
    }
  },
  backdrop: {
    zIndex: 9999999,
    color: "#000"
  }
});

function Dongle() {
  const dispatch = useDispatch();
  const appdata = useSelector(appdataSelector);
  const navigate = useNavigate();
  const fileRef = useRef();
  let { id } = useParams();
  const toValidate = ['vendor', 'model', 'serial', 'userlevel', 'machinetype', 'expirationdate', 'name', 'password'];
  const [dateValid, setDateValid] = useState(false);
  const [fieldsValid, setFieldsValid] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState("");

  useEffect(() => {
    console.log("create dongle page loaded", id);
    // check if appdata ist loaded, if not, load it
    if (!appdata.loaded) {
      dispatch(appdata_fetchAppdata());
    }

    if (id) {
      dispatch(appdata_fetchDongleData(id))
    }
    else {
      console.log("reset!");
      dispatch(appdata_setDongleData({}));
      dispatch(appdata_updateNewDongleData({}));
    }
  }, [])

  useEffect(() => {
    if (!Boolean(id)) {
      if (appdata.newDongleData != {}) {
        dispatch(appdata_updateNewDongleData({}))
      }
      if (appdata.dongleData != {}) {
        dispatch(appdata_setDongleData({}))
      }
    }
  }, [id])


  useEffect(() => {
    if (!id && appdata.dongleData.id) {
      navigate('/app/dongle/' + appdata.dongleData.id)
    }
  }, [appdata.dongleData])

  useEffect(() => {

    console.log("dongle data updated", appdata.newDongleData);

    // check if newdongledata is emtpy, that means a new dongle is to be created, reset the accounttype
    // also do not execute the validation
    if (Object.keys(appdata.newDongleData).length === 0) {
      if (selectedAccountType) {
        setSelectedAccountType("");
      }
    }

    // execute validation
    
    let valid = true;

    // standard required fields
    toValidate.forEach(key => {
      if ((!appdata.newDongleData.hasOwnProperty(key) || appdata.newDongleData[key] == null) && valid == true) {
        valid = key;
      }
    })

    // accounttype
    // accounttype can be 1: ses or 3: ses,root
    let accounttypeIsRequired = false;
    if (valid && accounttypeIsRequired) {
      if (!appdata.newDongleData.accounttype || appdata.newDongleData.accounttype == "") {
        valid = "accounttype";
      }
    }

    if (valid === true && dateValid && !fieldsValid) {
      setFieldsValid(true);
    }
    else if (valid !== true || !dateValid && fieldsValid) {
      setFieldsValid(false);
    }

  }, [appdata.newDongleData])

  useEffect(() => {
    if (selectedAccountType != appdata.newDongleData.accounttype) {
      dispatch(appdata_updateNewDongleData({ key: "accounttype", value: selectedAccountType }));
    }
  }, [selectedAccountType]);

  if (!appdata.loaded) {
    return (
      <div className="loader-container">
        {" "}
        Fetching data...<br></br>
        <br></br> <Loader w="25" />{" "}
      </div>
    );
  }

  if (appdata.loadingDongleData) {
    return (
      <div className="loader-container">
        {" "}
        Loading dongle data...<br></br>
        <br></br> <Loader w="25" />{" "}
      </div>
    );
  }




  const updateNewDongleData = (key, value) => {
    console.log("update new dongle data", key, value);
    dispatch(appdata_updateNewDongleData({ key: key, value: value }));
  }

  const createDongle = () => {
    console.log("go create");
    dispatch(appdata_createDongle());
  }

  const downloadDongle = () => {
    console.log("go download");
    dispatch(appdata_downloadDongle());
  }

  const uploadDongleIdFile = (event) => {
    console.log("upload dongle file", event);

    let file = event.target.files[0];

    if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        console.log("file loaded \n", content);
        let matches = [...content.matchAll(/'/g)]
        const indexes = matches.map(match => match.index);

        if (indexes.length != 6) {
          dispatch(appdata_setSnackbar({ open: true, severity: "error", message: "Invalid dongle file" }));
          return;
        }


        let vendor = content.substring(indexes[0] + 1, indexes[1]);
        if (vendor != "") {
          dispatch(appdata_updateNewDongleData({ key: "vendor", value: vendor }));
        }
        let model = content.substring(indexes[2] + 1, indexes[3]);
        if (model != "") {
          dispatch(appdata_updateNewDongleData({ key: "model", value: model }));
        }

        let serial = content.substring(indexes[4] + 1, indexes[5]);
        if (serial != "") {
          dispatch(appdata_updateNewDongleData({ key: "serial", value: serial }));
        }       

        // clear fileinput
        const fileInput = document.getElementById('fileInput');
        fileInput.value = '';

      };

      reader.readAsText(file);
    }

  }



  let dataset = id ? appdata.dongleData : appdata.newDongleData;




  let userlevelSelected = null;
  if (dataset.userlevel) {
    userlevelSelected = appdata.listValues.userlevels.find((element) => element.id == dataset.userlevel)
  }

  let machinetypeSelected = null;
  if (dataset.machinetype) {
    machinetypeSelected = appdata.listValues.machinetypes.find((element) => element.id == dataset.machinetype)
  }

  let accounttypeSelected = null;
  if (dataset.accounttype) {
    accounttypeSelected = appdata.listValues.accounttypes.find((element) => element.id == dataset.accounttype)
  }

  if (dataset.vendor) {
    console.log("vendor set", dataset.vendor);
  }

  if (id && dataset.accounttype && dataset.accounttype != selectedAccountType) {
    setSelectedAccountType(dataset.accounttype);
  }

  let validAccounttypes = appdata.listValues.accounttypes;
  validAccounttypes = validAccounttypes.filter(accounttype => accounttype.value !== 'root');

  return (
    <Grid
      className="mt-2"
      container
      spacing={2}
      alignItems="top"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} md={10}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultMaterialTheme}>
            <Card>
              <article className="article">
                <div className="container-fluid no-breadcrumb chapter FormIntegration">
                  <Grid container justify="space-between">
                    <Grid item xs={12} sm={12}>
                      {Boolean(id) &&
                        <h2 className="article-title">
                          Dongledata
                        </h2>
                      }
                      {!Boolean(id) &&
                        <h2 className="article-title">
                          Create new Dongle
                        </h2>
                      }
                      <div className="mt-4">

                        <div className="d-md-flex align-items-center">

                          {/* vendor */}
                          {/* vendor */}
                          {/* vendor */}
                          {/* vendor */}
                          <TextField
                            InputProps={{
                              readOnly: Boolean(id)
                            }}

                            className="mr-3"
                            sx={{ width: 300 }}
                            label="Vendor"
                            value={dataset.vendor ? dataset.vendor : ''}
                            onChange={(event) => updateNewDongleData("vendor", event.target.value)}
                          />

                          {/* model */}
                          {/* model */}
                          {/* model */}
                          {/* model */}
                          <TextField
                            InputProps={{
                              readOnly: Boolean(id)
                            }}

                            className="mr-3"
                            sx={{ width: 300 }}
                            label="Model"
                            value={dataset.model ? dataset.model : ''}
                            onChange={(event) => updateNewDongleData("model", event.target.value)}
                          />

                          {/* serial number */}
                          {/* serial number */}
                          {/* serial number */}
                          {/* serial number */}
                          <TextField
                            InputProps={{
                              readOnly: Boolean(id)
                            }}

                            className="mr-3"
                            sx={{ width: 300 }}
                            label="Serial number"
                            value={dataset.serial ? dataset.serial : ''}
                            onChange={(event) => updateNewDongleData("serial", event.target.value)}
                          />

                          {/* upload button */}
                          {/* upload button */}
                          {/* upload button */}
                          {/* upload button */}
                          <input
                            type="file"
                            accept=".id,.txt"
                            style={{ display: 'none' }}
                            id="fileInput"
                            onChange={uploadDongleIdFile}
                          />
                          <label htmlFor="fileInput" style={{ marginBottom: "0px", display: Boolean(id) ? "none" : "block" }}>
                            <Button variant="contained" component="span" sx={{ minHeight: "54px" }} >
                              Upload ID File
                            </Button>
                          </label>


                        </div>
                        <hr></hr>
                        <div className="d-md-flex ">
                          {/* username */}
                          {/* username */}
                          {/* username */}
                          {/* username */}
                          <TextField
                            InputProps={{
                              readOnly: Boolean(id)
                            }}
                            className="mb-3 mb-md-0 mr-3"
                            sx={{ width: 300 }}
                            label="Name"
                            value={dataset.name ? dataset.name : ''}
                            onChange={(event) => updateNewDongleData("name", event.target.value)}
                          />


                          {/* password */}
                          {/* password */}
                          {/* password */}
                          {/* password */}
                          <br></br>
                          <TextField
                            InputProps={{
                              readOnly: Boolean(id)
                            }}
                            className=""
                            sx={{ width: 300 }}
                            label="Password"
                            value={dataset.password ? dataset.password : ''}
                            onChange={(event) => updateNewDongleData("password", event.target.value)}
                          />

                        </div>

                        <hr></hr>

                        <div className="d-md-flex">

                          {/* userlevel */}
                          {/* userlevel */}
                          {/* userlevel */}
                          {/* userlevel */}
                          <Autocomplete
                            readOnly={Boolean(id)}
                            className="mb-3 mb-md-0 mr-3"
                            key={"userlevel"}
                            id="combo-box-demo"
                            options={appdata.listValues.userlevels}
                            value={userlevelSelected}
                            getOptionLabel={option => option.value ? option.value : ''}
                            sx={{ width: 300 }}
                            renderInput={params => <TextField {...params} label="User level" />}
                            renderOption={(props, option, state) => {
                              const key = props.id;
                              return (
                                <li {...props} key={key}>
                                  {option["value"]}
                                </li>
                              );
                            }}
                            onChange={(event, value) => {
                              updateNewDongleData(
                                "userlevel",
                                value ? value["id"] : null
                              );
                            }}
                          />

                          {/* machinetype */}
                          {/* machinetype */}
                          {/* machinetype */}
                          {/* machinetype */}
                          <Autocomplete
                            readOnly={Boolean(id)}
                            className="mb-3 mb-md-0 mr-3"
                            key={"Machinetype"}
                            id="combo-box-demo"
                            options={appdata.listValues.machinetypes}
                            value={machinetypeSelected}
                            getOptionLabel={option => option.key ? option.key : ''}
                            sx={{ width: 300 }}
                            renderInput={params => <TextField {...params} label="Machine type" />}
                            renderOption={(props, option, state) => {
                              const key = props.id;
                              return (
                                <li {...props} key={key}>
                                  {option["key"]}
                                </li>
                              );
                            }}
                            onChange={(event, value) => {
                              updateNewDongleData(
                                "machinetype",
                                value ? value["id"] : null
                              );
                            }}
                          />




                          {/* accountype */}
                          {/* accountype */}
                          {/* accountype */}
                          {/* accountype */}
                          <div>
                            Account type:<br></br>
                            {validAccounttypes.map((accounttype) => (
                              <FormControlLabel
                                key={accounttype.id}
                                control={
                                  <Checkbox
                                    checked={selectedAccountType == accounttype.id}
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        setSelectedAccountType(accounttype.id);
                                      }
                                      else {
                                        setSelectedAccountType("");
                                      }
                                    }}
                                    name={accounttype.value}
                                    color="primary"
                                    disabled={(accounttype.value == "ses,root" && appdata.userPermissions.isAdmin != true) || Boolean(id)}
                                  />
                                }
                                label={accounttype.value}
                              />
                            ))}
                          </div>
                          {/*  <Autocomplete
                            readOnly={Boolean(id)}
                            className=""
                            key={"accounttype"}
                            id="combo-box-demo"
                            options={validAccounttypes}
                            value={accounttypeSelected}
                            getOptionLabel={option => option.value ? option.value : ''}
                            sx={{ width: 300 }}
                            renderInput={params => <TextField {...params} label="Accounttype" />}
                            renderOption={(props, option, state) => {
                              const key = props.id;
                              return (
                                <li {...props} key={key}>
                                  {option["value"]}
                                </li>
                              );
                            }}
                            onChange={(event, value) => {
                              updateNewDongleData(
                                "accounttype",
                                value ? value["id"] : null
                              );
                            }}
                          />  */}
                        </div>
                        <hr></hr>
                        <div>
                        Expiry date:<br></br><br></br>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                            <DatePicker
                              readOnly={Boolean(id)}
                              disablePast
                              value={dataset.expirationdate ? dayjs(dataset.expirationdate) : null}
                              format="DD/MM/YYYY"
                              maxDate={dayjs().add(3, 'months')}
                              sx={{ width: 300 }}
                              onChange={(event, value) => {
                                if (!value.validationError && !dateValid) {
                                  setDateValid(true)
                                }
                                else if (value.validationError && dateValid) {
                                  setDateValid(false)
                                }
                                updateNewDongleData(
                                  "expirationdate",
                                  event && event.$d ? dayjs(event.$d).format("YYYYMMDD") : null
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="d-flex">
                          {!appdata.creatingDongle && !id && (
                            <div>
                              <Button disabled={!fieldsValid} onClick={createDongle} color="primary" size="large" variant="contained" className="mt-4 mr-3 mb-3">create</Button>
                            </div>
                          )}

                          {id && (
                            <div>
                              <Button onClick={downloadDongle} color="primary" size="large" variant="contained" className="mt-4 mr-3 mb-3">download</Button>
                            </div>
                          )}

                          {appdata.creatingDongle && (
                            <div className="loader-container text-left mt-4">
                              <span className="mr-3">Creating Dongle</span>
                              <Loader w="25" />
                            </div>
                          )}
                        </div>


                      </div>
                    </Grid>
                  </Grid>
                </div>
              </article>
            </Card>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
    </Grid>
  );
}

export default Dongle;
