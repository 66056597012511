// mediatel

// update react-router-dom 5 to 6
// replace Redirect with Navigate
// https://stackoverflow.com/questions/63690695/react-redirect-is-not-exported-from-react-router-dom

import APPCONFIG from "constants/appConfig";
import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import Cookies from "universal-cookie";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("besiUser4")
    ? JSON.parse(window.localStorage.getItem("besiUser4"))
    : {};

export const setUser = user =>
  window.localStorage.setItem("besiUser4", JSON.stringify(user));

export const redirectOnError = error => {
  // handle error

  console.error("redirect on redirectOnError");
  console.error(error);

  if (typeof error.response === "undefined") {
    //    console.error("cannot connect to server - is VPN active?")
  } else {
    if (error.response.status === 401) {
      var timeoutVar, testUser;
      timeoutVar = setTimeout(
        function() {
          testUser = getUser();

          if (testUser.ipCheckDone) {
            clearTimeout(timeoutVar);
            if (testUser.validIP) {
              fakeAuth.logout();

              redirectAndSaveState(error);
            }
          }
        },
        200,
        error
      );
    }
  }
};

export const redirectAndSaveState = error => {
  const url = APPCONFIG.appBackendHostname + "login";

  const cookies = new Cookies();
  cookies.set("mySavedUrl", window.location.href);
  // user not in groups
  console.log("goto Login",url);
  window.location.href = url;
};

export const validateIP = () => {
  const axiosConfig = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true
  };

  const user = getUser();
  if (!user.ipCheckDone) {
    // check IP RANGE of APP
    axios
      .post(
        APPCONFIG.appBackendHostname + "api/validateip",
        {
          withCredentials: true
        },
        axiosConfig
      )
      .then(res => {
        /* check IP range*/
        return setUser({
          ipCheckDone: true,
          validIP: res.data.isvalid
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  }
};

export const logout = callback => {
  setUser({});
  callback();
};

/* A fake authentication function */
export const fakeAuth = {
  isAuthenticated: false,

  logout() {
    this.isAuthenticated = false;
  }
};

export const getAuthToken = () => {
  let token = sessionStorage.getItem("authToken");

  if (token) {
    return token;
  }

  return "";
};
export const setAuthToken = sessionId => {
  sessionStorage.setItem("authToken", sessionId);
};

//Private router function
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const resultAlwaysOK = true;

  return (
    <Routes>
      <Route
        {...rest}
        render={props =>
          true === resultAlwaysOK ? (
            <Component {...props} />
          ) : (
            <Navigate
              to={{
                pathname: APPCONFIG.permissionDeniedPathName,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    </Routes>
  );
};
