import { createSlice } from "@reduxjs/toolkit";
import APPCONFIG from "constants/appConfig";
import axios from "axios";
import { Navigate } from "react-router";
import { redirectOnError } from "services/auth";


export const initialState = {
  language: "DE",
  loaded: false,
  appState: "",
  loadingRequests: false,
  loading: false,
  loadingDongleData: false,
  creatingDongle: false,
  listValues: [],
  requests: [],
  newDongleData: {},
  dongleData: {},
  userPermissions: { isAdmin: false, isAllowedUser: false },
  snackbar: { open: false, severity: "info", message: "default" },
};

export const appdataSlice = createSlice({
  name: "appdata",
  initialState,
  reducers: {
    appdata_updateNewDongleData: (state, action) => {
      if (!action.payload.hasOwnProperty("key")) {
        state.newDongleData = {}
      }
      else {
        state.newDongleData = { ...state.newDongleData, [action.payload.key]: action.payload.value };
      }
    },
    appdata_setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    appdata_setDongleData: (state, action) => {
      state.dongleData = action.payload;
    },
    appdata_setListValues: (state, action) => {
      state.listValues = action.payload;
    },
    appdata_setRequests: (state, action) => {
      state.requests = action.payload;
    },
    appdata_setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    appdata_setCreatingDongle: (state, action) => {
      state.creatingDongle = action.payload;
    },
    appdata_setLoading: (state, action) => {
      state.loading = action.payload;
    },
    appdata_setLoadingDongledata: (state, action) => {
      state.loadingDongleData = action.payload;
    },
    appdata_setLoadingRequests: (state, action) => {
      state.loadingRequests = action.payload;
    },
    appdata_setAppLanguage: (state, action) => {
      state.language = action.payload;
    },
    appdata_setAppState: (state, action) => {
      state.appState = action.payload;
    },
    appdata_setSnackbar: (state, action) => {
      state.snackbar.severity = action.payload.severity
        ? action.payload.severity
        : "info";
      state.snackbar.message = action.payload.message
        ? action.payload.message
        : "";
      state.snackbar.open = action.payload.open ? action.payload.open : false;
    }
  }
});

export const {
  appdata_setLoaded,
  appdata_setLoading,
  appdata_setLoadingRequests,
  appdata_setAppLanguage,
  appdata_setAppState,
  appdata_setListValues,
  appdata_setRequests,
  appdata_updateNewDongleData,
  appdata_setSnackbar,
  appdata_setCreatingDongle,
  appdata_setDongleData,
  appdata_setLoadingDongledata,
  appdata_setUserPermissions
} = appdataSlice.actions;
export const appdataSelector = state => state.appdata;
export default appdataSlice.reducer;

export function appdata_fetchRequests() {
  console.log("appdata - fetchRequests")
  return async (dispatch, getState) => {
    if (getState().appdata.loadingRequests) {
      // already loading either cancel or return 
      return;
    }
    else {
      dispatch(appdata_setLoadingRequests(true));
    }

    axios
      .get(
        APPCONFIG.host +
        "api/donglegenerator/requests",
        {
          withCredentials: true,
          timeout: APPCONFIG.axiosTimeout,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        console.log("appdata got requests", response);
        if (response.status != 200) {
          // handle error message
        } else {
          // handle data

          let requests = JSON.parse(response.data);
          dispatch(appdata_setRequests(requests));

        }

        dispatch(appdata_setLoadingRequests(false));
      })
      .catch(er => {
        console.error("error on loading requests", er);

        // handle error

        dispatch(appdata_setLoadingRequests(false));

        redirectOnError(er);
      });



  }

}

export function appdata_fetchDongleData(id) {

  console.log("appdata - fetchdongledata");

  return async (dispatch, getState) => {

    if (getState().appdata.loadingDongleData) {
      // already loading either cancel or return 
      return;
    }
    else {
      dispatch(appdata_setLoadingDongledata(true))
    }

    axios
      .get(
        APPCONFIG.host +
        "api/donglegenerator/dongle/" + id,
        {
          withCredentials: true,
          timeout: APPCONFIG.axiosTimeout,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        console.log("got dongle", response);
        if (response.status != 200) {
          // handle error message
        } else {
          console.log("dongle ok", JSON.parse(response.data).data)

          if (response.data) {
            dispatch(appdata_setDongleData(JSON.parse(response.data).data));
          }

          dispatch(appdata_setLoadingDongledata(false))
          // handle data
          /*   dispatch(appdata_setLoaded(true));
            let appdata = JSON.parse(response.data).appdata;
            dispatch(appdata_setListValues(appdata));
   */
        }

        dispatch(appdata_setLoading(false));
      })
      .catch(er => {
        console.error("scheduledata error", er);

        // handle error

        dispatch(appdata_setLoading(false));
      });
  };
}

export function appdata_fetchAppdata($id = null) {

  console.log("appdata - fetchData");

  return async (dispatch, getState) => {

    if (getState().appdata.loading) {
      // already loading either cancel or return 
      return;
    }
    else {
      dispatch(appdata_setLoading(true))
    }

    axios
      .get(
        APPCONFIG.host +
        "api/app/appdata",
        {
          withCredentials: true,
          timeout: APPCONFIG.axiosTimeout,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        console.log("appdata got appdata", response);
        if (response.status != 200) {
          // handle error message
        } else {
          // handle data
          dispatch(appdata_setLoaded(true));
          let appdata = JSON.parse(response.data).appdata;
          dispatch(appdata_setListValues(appdata));

        }

        dispatch(appdata_setLoading(false));
      })
      .catch(er => {
        console.error("scheduledata error", er);

        // handle error

        dispatch(appdata_setLoading(false));
      });
  };
}

export function appdata_downloadDongle() {
  console.log("download dongle");

  return async (dispatch, getState) => {
    axios({
      url: APPCONFIG.host + "api/donglegenerator/download/" + getState().appdata.dongleData.id,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      console.log("download, response", response);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', "dcdongle");
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      //redirectOnError(error);
    });
  }


}

export function appdata_createDongle() {



  return async (dispatch, getState) => {

    if (getState().appdata.creatingDongle) {
      return;
    }

    dispatch(appdata_setCreatingDongle(true));



    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      withCredentials: true
    };

    axios
      .post(
        APPCONFIG.host + "api/donglegenerator/create",

        getState().appdata.newDongleData,

        axiosConfig
      )
      .then(response => {
        console.log("dongle created", response);

        dispatch(appdata_setCreatingDongle(false));

        if (response.status !== 200) {
          dispatch(appdata_setSnackbar({ open: true, severity: "error", message: response.statusText }))
          dispatch(appdata_setCreatingDongle(false));


        }
        else {
          dispatch(appdata_setSnackbar({ open: true, severity: "info", message: "Dongle generated" }))

          let responsedata = JSON.parse(response.data);

          let dongledata = { ...getState().appdata.newDongleData, "id": responsedata.id }
          console.log("dongledata", dongledata)
          dispatch(appdata_setDongleData(dongledata))

        }


      })
      .catch(error => {
        console.error("error on creation", error);
        dispatch(appdata_setSnackbar({ open: true, severity: "error", message: "dongle not generatod" }))
        dispatch(appdata_setCreatingDongle(false));
      })
  }
}
