import React from "react";
import "./loader.scss";

const Loader = function(props) {
  let w = props && props.w ? props.w : 60;
  return(
  <svg
    className="spinner"
    width={w+"px"}
    height={w*2+"px"}
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="path"
      fill="none"
      strokeWidth="4"
      strokeLinecap="round"
      cx="30"
      cy="30"
      r="28"
    ></circle>
  </svg>
  )
};

export default Loader;
