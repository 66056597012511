import { mkConfig, generateCsv, download } from "export-to-csv";

export { exportDataToCSV }

const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "donglegenerator" });

function exportDataToCSV(dataSource) {
    console.log("export data to csv", dataSource)
    const csv = generateCsv(csvConfig)(dataSource);
    download(csvConfig)(csv)
}

