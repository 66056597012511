import React, { useEffect } from "react";
import { Button, Card, createTheme } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { appdataSelector, appdata_fetchAppdata, appdata_fetchRequests, appdata_setDongleData, appdata_updateNewDongleData } from "reducers/appdata/appdata";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "components/Loading/Loader";
import { useNavigate } from "react-router";
import { exportDataToCSV } from "./overviewHelper"


const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#008752"
    }
  },
  backdrop: {
    zIndex: 9999999,
    color: "#000"
  }
});

function Overview() {

  const appdata = useSelector(appdataSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Overview loaded");
    if (!appdata.loaded) {
      dispatch(appdata_fetchAppdata());
    }

    dispatch(appdata_fetchRequests());

    dispatch(appdata_setDongleData({}))
    dispatch(appdata_updateNewDongleData({}))
  }, [])


  if (appdata.loadingRequests || !appdata.loaded) {
    return (
      <div className="loader-container">
        {" "}
        Fetching requests...<br></br>
        <br></br> <Loader w="25" />{" "}
      </div>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80
    },
    {
      field: "requestor_name",
      headerName: "requestor",
      width: 150
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 150,
      /* valueGetter: (params) => {
        return appdata.listValues.vendors.find(element => element.id == params.value).name
      } */
    },
    {
      field: "model",
      headerName: "Model",
      width: 150,
      /* valueGetter: (params) => {
        let models = appdata.listValues.vendors.find(element => element.id == params.row.vendor).models;
        let model = models.find(element => element.id == params.value);
        if (model) return model.name;
        else return "unknown"
      } */
    },
    {
      field: "serial",
      headerName: "Serial",
      width: 200,
    }
    ,
    {
      field: "expirationdate",
      headerName: "Expiration",
      width: 150,
    }
    ,
    {
      field: "userlevel",
      headerName: "Userlevel",
      width: 100,
      valueGetter: (params) => {
        let userlevel = params.row.userlevel ? appdata.listValues.userlevels.find(element => element.id == params.row.userlevel).value : "";
        return userlevel;
      }
    },
    {
      field: "machinetype",
      headerName: "Machinetype",
      width: 100,
      valueGetter: (params) => {
        let machinetype = params.row.machinetype ? appdata.listValues.machinetypes.find(element => element.id == params.row.machinetype).key : "";
        return machinetype;
      }
    },
    {
      field: "accounttype",
      headerName: "Accounttype",
      width: 100,
      valueGetter: (params) => {
        let accounttype = params.row.accounttype && params.row.accounttype != null ? appdata.listValues.accounttypes.find(element => element.id == params.row.accounttype).value : "";
        return accounttype;
      }
    }

  ]

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    console.log(`menu ${params.row.id} clicked`);
    navigate(`/app/dongle/${params.row.id}`);
  };


  const keysToKeep = ['id', 'requestor', 'vendor', 'model', 'serial', 'expiration', 'userlevel', 'machinetype', 'accounttype']
  const objfilter = (data) => {
    let dataforcsv = Object.fromEntries(Object.entries(data).filter(a => keysToKeep.includes(a[0])))

   Object.values(appdata.listValues.userlevels).map(entry => {

      if(dataforcsv.userlevel == entry.id){
        dataforcsv.userlevel = entry.value
      }

     })

   Object.values(appdata.listValues.machinetypes).map(entry => {

      if(dataforcsv.machinetype == entry.id){
        dataforcsv.machinetype = entry.value
      }
    
    })

    Object.values(appdata.listValues.accounttypes).map(entry => {

        if(dataforcsv.accounttype == entry.id){
          dataforcsv.accounttype = entry.value
      }
        
    })

    return dataforcsv
  }

  const exportToCSVHandler = () => {

    // format data
    let data = [];
    appdata.requests.forEach(entry => {
      data.push(objfilter(entry));
    })
    
    exportDataToCSV(data);
  }

  return (
    <Grid
      className="p-4"
      container
      spacing={2}
      alignItems="top"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} md={12}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultMaterialTheme}>
            <Card>
              <article className="article">
                <div className="container-fluid no-breadcrumb chapter FormIntegration">
                  <Grid container justify="space-between">
                    <Grid item xs={12} sm={12}>
                      <h2 className="article-title">Overview</h2>
                      <div className="mt-4" style={{ height: 500 }}>
                        <DataGrid
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                          }}
                          onRowClick={handleRowClick}
                          columns={columns}
                          rows={appdata.requests}
                        ></DataGrid>
                      </div>

                      <Button onClick={exportToCSVHandler} color="primary" size="large" variant="contained" className="mt-4 mr-3 mb-3">Export to CSV</Button>
                    </Grid>

                  </Grid>

                </div>
              </article>
            </Card>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
    </Grid>
  );
}

export default Overview;
