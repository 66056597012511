import React from "react";
// import Avatar from '@mui/material/Avatar';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { withRouter } from "components/Wrappers/react-router-wrapper";
import APPCONFIG from "constants/appConfig";
import axios from "axios";
import { redirectOnError, getUser } from "services/auth";
import Cookies from "universal-cookie";

import { useDispatch } from "react-redux";
import appdata, { appdata_setAppLanguage } from "reducers/appdata/appdata";

const cookies = new Cookies();
//class NavRightList extends React.Component {
function NavRightList() {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [language, setLanguage] = React.useState(null);

  React.useEffect(() => {
    console.log("navrightlist - load", user);
    if (!user) {
      setUser(getUser());
    }
  }, []);

  /* var state = {
     anchorEl: null, 
    user: getUser(),
    language: "DE"
  }; */

  var languagecookie = cookies.get("language");
  if (languagecookie) {
    //this.setState({ language: languagecookie });
    if (language != languagecookie) setLanguage(languagecookie);
    if (appdata.language != languagecookie) {
      dispatch(appdata_setAppLanguage(languagecookie));
    }
  } else {
    if (language != "DE") {
      setLanguage("DE");
      dispatch(appdata_setAppLanguage("DE"));
    }
  }

  const handleClick = event => {
    // console.log( event)
    //this.setState({ anchorEl: event.currentTarget });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    /* this.setState({ anchorEl: null }); */
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("authToken");
    /* this.setState({ anchorEl: null }); */
    setAnchorEl(null);
    window.location.href = APPCONFIG.logoutUrl;
  };

  const handleChangeLanguage = language => {
    console.log("handle language change", language);
    cookies.set("language", language);
    setLanguage(language);
    dispatch(appdata_setAppLanguage(language));
  };

  console.log("navrightlist - render", user);

  if (user == null) {
    console.log("navrightlist - render is null", user);
    return <div>no</div>;
  }

  console.log("navrightlist - render go", user);

  /* render() { */
  //const { anchorEl, user } = this.state;
  //const dispatch = useDispatch();
  return (
    <ul className="list-unstyled float-right">
      <span style={{ lineHeight: "50px" }}>
        <IconButton
          className="header-btn"
          aria-owns={anchorEl ? "app-header-menu" : null}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <i className="d-none d-sm-inline">{user ? user.name : ""} &nbsp; </i>{" "}
          <i className="material-icons header-avatar">person</i> <span></span>
        </IconButton>

{/*         <Box sx={{ minWidth: 80, display: "inline-block", marginRight: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label="Language"
              onChange={event => {
                console.log("change", event);
                handleChangeLanguage(event.target.value);
              }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"DE"}>German</MenuItem>
            </Select>
          </FormControl>
        </Box> */}

        {/*  <IconButton
          style={{borderRadius:0}}
            size="large"
            onClick={() => {
              console.log("click");
              //dispatch(scheduledata_setAppLanguage("DE"));
              this.handleChangeLanguage("DE")
            }}
          >
            <i className="">
              {" "}
              <img
                style={{ maxHeight: 20, marginLeft: 10 }}
                src={at}
                alt="BESI"
              />
            </i>
          </IconButton> */}
      </span>
      {/* <li className="list-inline-item search-box seach-box-right d-none d-md-inline-block">
          <div className="search-box-inner">
            <div className="search-box-icon"><MaterialIcon icon="search" /></div>
            <input type="text" placeholder="search..." />
            <span className="input-bar"></span>
          </div>
        </li>*/}
      <li
        className="list-inline-item search-box seach-box-right d-none d-md-inline-block"
        style={{ marginRight: "10px" }}
      ></li>
      <li style={{ marginRight: "10px" }}>
        {/*     <IconButton
            className="header-btn"
            aria-owns={anchorEl ? 'app-header-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Avatar alt="avatar" src="assets/images-demo/g1.jpg" className="rounded-circle header-avatar" />

          </IconButton>
*/}
        <Menu
          id="app-header-menu"
          className="app-header-dropdown"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <div>
              <span>Signed in as</span>
              <strong>{user ? user.name : ""}</strong>
            </div>
          </MenuItem>
          <div className="divider divider-solid my-1"></div>
          <MenuItem onClick={handleLogout}>
            {" "}
            <a href="#logout">
              {" "}
              <i className="material-icons">forward</i> <span>Log Out</span>
            </a>{" "}
          </MenuItem>
        </Menu>
      </li>
    </ul>
  );
  /* } */
}

//export default withRouter(NavRightList);
export default NavRightList;
