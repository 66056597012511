import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Provider } from "react-redux";
import { Route, Routes, HashRouter } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import App from "./App";

function Root(props) {
  console.log("Root", props);

  const { store, history } = props;
  return (
    <Provider store={store}>
      <HashRouter history={history}>
        <ScrollToTop>
          <Routes>
            <Route path="/*" element={<App store={store} />} />
          </Routes>
        </ScrollToTop>
      </HashRouter>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
