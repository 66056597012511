import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "jquery-slimscroll/jquery.slimscroll.min";
import { connect } from "react-redux";
import { getUser } from "services/auth";

class SidebarContent extends React.Component {
  constructor(props) {
    console.log("userprops", props);
    super(props);
    this.state = {
      dataInitialised: false,
      isAdmin: null,
    };
  }

  componentDidMount() {
    /* const { history } = this.props; */
    const nav = this.nav;
    const $nav = $(nav);

    // scroll
    $nav.slimscroll({
      height: "100%"
    });

    

    // Append icon to submenu
    $nav
      .find(".prepend-icon")
      .prepend('<i class="material-icons">keyboard_arrow_right</i>');

    // AccordionNav
    const slideTime = 250;
    const $lists = $nav.find("ul").parent("li");
    $lists.append('<i class="material-icons icon-has-ul">arrow_drop_down</i>');
    const $As = $lists.children("a");

    // Disable A link that has ul
    $As.on("click", event => event.preventDefault());

    // Accordion nav
    $nav.on("click", e => {
      const target = e.target;
      const $parentLi = $(target).closest("li"); // closest, insead of parent, so it still works when click on i icons
      if (!$parentLi.length) return; // return if doesn't click on li
      const $subUl = $parentLi.children("ul");

      // let depth = $subUl.parents().length; // but some li has no sub ul, so...
      const depth = $parentLi.parents().length + 1;

      // filter out all elements (except target) at current depth or greater
      const allAtDepth = $nav.find("ul").filter(function() {
        if ($(this).parents().length >= depth && this !== $subUl.get(0)) {
          return true;
        }
        return false;
      });
      allAtDepth
        .slideUp(slideTime)
        .closest("li")
        .removeClass("open");

      // Toggle target
      if ($parentLi.has("ul").length) {
        $parentLi.toggleClass("open");
      }
      $subUl.stop().slideToggle(slideTime);
    });

    console.log("sidenavcontent", location);

    // HighlightActiveItems
    const $links = $nav.find("a");
    const currentLocation = location;

    function highlightActive(pathname) {
      const path = `#${pathname}`;

      $links.each((i, link) => {
        const $link = $(link);
        const $li = $link.parent("li");
        const href = $link.attr("href");

        if ($li.hasClass("active")) {
          $li.removeClass("active");
        }
        if (path.indexOf(href) === 0) {
          $li.addClass("active");

          // check hierarchy - chechenblaikner
          let liUlLiLength = $li.parent("ul").has("li");
          if (liUlLiLength) {
            $li
              .parent("ul")
              .has("li")
              .addClass("open");
            $li
              .parent("ul")
              .has("li")
              .css("display", "block");
            $li.addClass("active");
          }
        }
      });
    }

    highlightActive(currentLocation.pathname);
    //TODO: add histiory / locaiton change event listener
    //https://stackoverflow.com/questions/68782781/react-router-v6-history-listen
    /*history.listen(location => {
      highlightActive(location.pathname);
    }); */
  }
  /* https://fonts.google.com/icons */

  render() {
  
    
    return (
      <div>
        <ul
          className="d-print-none nav"
          ref={c => {
            this.nav = c;
          }}
        >
          
          <li className=" nav-header">
            <span>Dongle Generator</span>
          </li>

          <li style={{ borderTop: "1px solid white", marginTop: "10px" }}>
            <Link className="mt-2" to="/app/overview" replace>
              <i className="nav-icon material-icons">description</i>
              <span>Overview</span>
            </Link>
          </li>
          <li>
            <Link to="/app/dongle">
              <i className="nav-icon material-icons">library_add</i>
              <span>Create dongle</span>
            </Link>
          </li>

        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme
});

export default connect(mapStateToProps)(SidebarContent);

