import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { withRouter } from "components/Wrappers/react-router-wrapper";
import $ from "jquery";
import APPCONFIG from "constants/appConfig";
import { toggleCollapsedNav } from "actions/settingsActions";
import SidenavContent from "./SidenavContent";

import logo from "../../../assets/images/besi_logo.png";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: null
    };
  }

  componentDidUpdate() {
    if (location.hash != this.state.hash && APPCONFIG.AutoCloseMobileNav) {
      const $body = $("#body");
      this.setState({ hash: location.hash });
      setTimeout(() => {
        $body.removeClass("sidebar-mobile-open");
      }, 0);
    }
  }
  componentDidMount() {
    // AutoCloseMobileNav
    const { history } = this.props;

    const $body = $("#body");

    if (APPCONFIG.AutoCloseMobileNav) {
      /*   history.listen(location => {
        setTimeout(() => {
          $body.removeClass("sidebar-mobile-open");
        }, 0);
      }); */
    }
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed;
    const { handleToggleCollapsedNav } = this.props;
    handleToggleCollapsedNav(val);
  };

  render() {
    const { navCollapsed, colorOption } = this.props;
    let toggleIcon = null;
    if (navCollapsed) {
      toggleIcon = <i className="material-icons">radio_button_unchecked</i>;
    } else {
      toggleIcon = <i className="material-icons">radio_button_checked</i>;
    }

    return (
      <nav
        className={classnames("app-sidebar", {
          "bg-color-light":
            ["31", "32", "33", "34", "35", "36"].indexOf(colorOption) >= 0,
          "bg-color-dark":
            ["31", "32", "33", "34", "35", "36"].indexOf(colorOption) < 0
        })}
      >
        <section
          className={classnames("sidebar-header", "d-print-none", {
            "bg-color-light":
              ["11", "12", "13", "14", "15", "16", "21"].indexOf(colorOption) >=
              0,
            "bg-color-dark": colorOption === "31",
            "bg-color-primary": ["22", "32"].indexOf(colorOption) >= 0,
            "bg-color-success": ["23", "33"].indexOf(colorOption) >= 0,
            "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
            "bg-color-warning": ["25", "35"].indexOf(colorOption) >= 0,
            "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
          })}
        >
          <div className="brand d-inline">
            <div className="mdtl-logo">
              <img src={logo} alt="BESI" />
            </div>
          </div>
        </section>

        <section className="sidebar-content">
          <SidenavContent />
        </section>

        {/* <section className="sidebar-footer">
          <ul className="nav">
            <li>
              <a target="_blank" href={APPCONFIG.helpUrl}>
                <i className="nav-icon material-icons">help</i>
                <span className="nav-text">
                  <span>Help</span> & <span>Support</span>
                </span>
              </a>
            </li>
          </ul>
        </section> */}
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  navCollapsed: state.settings.navCollapsed,
  colorOption: state.settings.colorOption
});

const mapDispatchToProps = dispatch => ({
  handleToggleCollapsedNav: isNavCollapsed => {
    dispatch(toggleCollapsedNav(isNavCollapsed));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
