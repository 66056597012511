import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import APPCONFIG from "constants/appConfig";
import axios from "axios";
import { getUser, redirectAndSaveState, setUser } from "services/auth";
import Sidenav from "components/Layout/Sidenav";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import Loader from "components/Loading/Loader";
import { appdataSelector, appdata_setAppState, appdata_setSnackbar,appdata_setUserPermissions, appdata_updateNewDongleData } from "reducers/appdata/appdata";
import { useDispatch, useSelector } from "react-redux";
import Dongle from "routes/app/routes/dongle/dongle";
import Overview from "routes/app/routes/overview/overview";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function MainApp(props) {
  console.log("new Mainapp", props);
  const dispatch = useDispatch();
  const location = useLocation();
  const appdata = useSelector(appdataSelector);
  const [hasLogin, setHasLogin] = useState(false);

  useEffect(() => {
    // execute on location change
    console.log("Location changed, set app state", location.pathname);

    switch (location.pathname) {
      case "/app/overview":
        dispatch(appdata_setAppState("overview"));
        break;
      case "/app/dongle":
        dispatch(appdata_updateNewDongleData({}));
        dispatch(appdata_setAppState("dongle"));
        break;
      default:
        console.log('non handled location', location.pathname);
        break;
    }
  }, [location]);

  const getMe = () => {
    console.log("getMe");
    axios
      .get(APPCONFIG.host + "api/me", {
        withCredentials: true,
        timeout: APPCONFIG.axiosTimeout
      })
      .then(res => {
        console.log("MainApp - getme - got user", res.data);
        console.log("MainApp - getme - got user, admin", res.data.isAdmin);
        console.log("MainApp - getme - got user, allowed", res.data.isAllowedUser);
        dispatch(appdata_setUserPermissions({ isAdmin: res.data.isAdmin, isAllowedUser: res.data.isAllowedUser }));
        setUser({ ...getUser(), ...res.data });
        if (res.data.employeeId) {
          setHasLogin(true);
        }

         if (!res.data.isAllowedUser) {
           window.location.href =
            APPCONFIG.appFrontendHostname + "#/exception/403"; 
        }  
      })
      .catch(error => {
        console.error(error);
        if (
          error.response &&
          error.response.status &&
          error.response.status === 403
        ) {
          // user not in groups
          window.location.href =
            APPCONFIG.appFrontendHostname + "#/user/notallowed";
        } else {
          redirectAndSaveState(error);
        }
        // handle error
      });
  };

  useEffect(() => {
    console.log("Mainapp - init", location);

    let isRoot = location && location.hash === "" ? true : false;

    if (location.pathname.includes('/dongle/')) {
      isRoot = false
    }

    if (isRoot) {
      console.log("MainApp is root, frontend:" + APPCONFIG.appFrontendHostname);
      let url = APPCONFIG.appFrontendHostname + "#/app/dongle";
      window.location.href = url;
    }

    getMe();

    sessionStorage.removeItem("besiUser4");
    let sesionId = localStorage.getItem("laravelSession");
    if (sesionId) {
      axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "laravelSession"
      );
    }
  }, []);

  if (!hasLogin) {
    return (
      <div className="d-flex justify-content-center">
        <Loader></Loader>
      </div>
    );
  }

  return (
    <div className="main-app-container">
      <Sidenav />
      <section id="page-container" className="app-page-container">
        <Header />
        <div className="app-content-wrapper">
          <div className="app-content">
            <div className="h-100">
              <Routes>
                <Route path="/overview" element={<Overview />} />
                <Route path="/dongle/:id?" element={<Dongle />} />
              </Routes>

              <Snackbar
                sx={{ maxWidth: '100%' }}
                className="mt-6"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={appdata.snackbar.open}
                onClose={() => {
                  dispatch(appdata_setSnackbar({ open: false }));
                }}
                key={"ilovesnacks"}
                autoHideDuration={5000}
              >
                <Alert sx={{ maxWidth: '100%' }} severity={appdata.snackbar.severity}>
                  {appdata.snackbar.message}
                </Alert>
              </Snackbar>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps)(MainApp);
