import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
// import { Link } from 'react-router-dom';
import APPCONFIG from "constants/appConfig";
// import NavLeftList from './NavLeftList';
import NavRightList from "./NavRightList";
import $ from "jquery";
import DEMO from 'constants/demoData';

import logo from '../../../assets/images/besi_logo.png';

class Header extends React.Component {
  componentDidMount() {
    const sidebarToggler = this.sidebarBtn;
    const $sidebarToggler = $(sidebarToggler);
    const $body = $("#body");

    $sidebarToggler.on("click", e => {
      // _sidebar.scss, _page-container.scss
      $body.toggleClass("sidebar-mobile-open");
    });
  }

  render() {
    const { colorOption } = this.props;

    return (
      <section className={classnames("app-header", "d-print-none")}>
        <div
          className={classnames("app-header-inner", "d-print-none", {
            "bg-color-light":
              ["11", "12", "13", "14", "15", "16", "21"].indexOf(colorOption) >=
              0,
            "bg-color-dark": colorOption === "31",
            "bg-color-primary": ["22", "32"].indexOf(colorOption) >= 0,
            "bg-color-success": ["23", "33"].indexOf(colorOption) >= 0,
            "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
            "bg-color-warning": ["25", "35"].indexOf(colorOption) >= 0,
            "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
          })}
        >
          <div className="d-lg-none d-xl-none float-left">
            <a

              /* href={DEMO.link} */ className="header-icon toggle-sidebar-btn"
                                     ref={c => {
                                       this.sidebarBtn = c;
                                     }}
            >
              <i className="material-icons">menu</i>
            </a>
          </div>

          <div className="brand d-none d-lg-inline-block d-xl-inline-block">
            <div className="mdtl-logo">
              <img src={logo} alt="BESI" />
            </div>
          </div>

          <div className="top-nav-left d-none d-lg-inline-block d-xl-inline-block">
            {/* <NavLeftList /> */}{" "}
            <span className="mdtl-app-name">
              <a href="/">{APPCONFIG.brandHeaderName}</a>
            </span>
            {(window.location.href.includes("test"))  && (
              <span className="mdtl-app-name" style={{color:"red"}}> TESTSYSTEM</span>
            )}
            {(window.location.href.includes("localhost")) && (
              <span className="mdtl-app-name" style={{color:"red"}}> LOCALHOST </span>
            )}
          </div>

          <div className={classnames("top-nav-right", "d-print-none")}>
            <NavRightList />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  colorOption: state.settings.colorOption
});

export default connect(mapStateToProps)(Header);
