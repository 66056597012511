// wrapper to keep withrouter in react-router v6
//https://stackoverflow.com/questions/66465750/withrouter-is-not-exported-from-react-router-dom


import { useNavigate } from "react-router";

export const withRouter = Component => {
  const Wrapper = props => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};