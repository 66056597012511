import React from 'react';
import { store, history } from "./store/store";
import Root from './components/Root';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import {getAuthToken, setAuthToken} from './services/auth';
import Cookies from 'universal-cookie';
import { createRoot } from 'react-dom/client';


const query = new URLSearchParams(window.location.search);
const token = query.get('token')
if (token) {
  setAuthToken(token);
  const cookies = new Cookies();
  window.location.href = cookies.get('mySavedUrl');
}
axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAuthToken();


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root store={store} history={history}/>);
if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    root.render(<NewRoot store={store} history={history}/>);
  });
}


serviceWorker.unregister();
