import React from 'react';
import APPCONFIG from 'constants/appConfig';
 
//  <span><a href="https://www.mediatel.at" target="_blank">Built with Love <i className="material-icons">favorite_border</i></a></span>
class Footer extends React.Component {
  render() {
    return (
      <section className="app-footer">
        <div className="container-fluid">
          <span className="float-left">
            <span>Copyright © {APPCONFIG.brand} {APPCONFIG.year}</span>
          </span>
          <span className="float-right">
              
          </span>
        </div>
      </section>
    );
  }
}

export default Footer;
