import { configureStore} from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { composeWithDevTools } from "@redux-devtools/extension";
import { createHashHistory } from "history";

// reducers
import settings from "../reducers/settings";
import appdata from "reducers/appdata/appdata";


// https://github.com/salvoravida/redux-first-history

const { createReduxHistory, routerReducer } = createReduxHistoryContext({
  history: createHashHistory()
});

const reducer = {
  router: routerReducer,
  settings: settings,
  appdata:appdata
};

export const store = configureStore({
  reducer: reducer,
  enhancer: [composeWithDevTools()]
});

export const history = createReduxHistory(store);
