import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";
import loadable from "react-loadable";
import LoadingComponent from "components/Loading";
import APPCONFIG from "constants/appConfig";

// = styles =
// 3rd
import "styles/bootstrap/bootstrap.scss";
// custom
import "styles/layout.scss";
import "styles/theme.scss";
import "styles/ui.scss";

import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import grayTheme from "./themes/grayTheme";
import MainApp from "./Layout/AppLayout/MainApp";

/* let MainApp = loadable({
  loader: () => import("components/Layout/AppLayout/MainApp"),
  loading: LoadingComponent
}); */
let Exception = loadable({
  loader: () => import("routes/exception/"),
  loading: LoadingComponent
});

let PageFullscreen = loadable({
  loader: () => import("routes/fullscreen/"),
  loading: LoadingComponent
});

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      layoutBoxed,
      navCollapsed,
      navBehind,
      fixedHeader,
      sidebarWidth,
      theme
    } = this.props;

    let materialUITheme;
    switch (theme) {
      case "gray":
        materialUITheme = grayTheme;
        break;
      case "dark":
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = lightTheme;
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={materialUITheme}>
          <div id="app-inner">
            <div className="preloaderbar hide">
              <span className="bar" />
            </div>
            <div
              className={classnames("app-main h-100", {
                "fixed-header": fixedHeader,
                "nav-collapsed": navCollapsed,
                "nav-behind": navBehind,
                "layout-boxed": layoutBoxed,
                "theme-gray": theme === "gray",
                "theme-dark": theme === "dark",
                "sidebar-sm": sidebarWidth === "small",
                "sidebar-lg": sidebarWidth === "large"
              })}
            >
              <Routes>
                {/* <Route path={`${match.url}app`} element={<MainApp />} />
                <Route path={`${match.url}exception`} element={<Exception />} /> */}
                {/* <Route path="/app" element={<MainApp />} /> */}
                <Route path="/*" element={<MainApp/>} />
                <Route path="app/*" element={<MainApp/>} />
                <Route path="/exception/*" element={<Exception />} />
                <Route exact path="/fullscreen" element={<PageFullscreen />} />
              </Routes>
            </div>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme
});

export default connect(mapStateToProps)(App);
